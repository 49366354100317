import { useFavicon, useTitle, useCookie, useLocalStorage } from 'react-use';

export type FaviconEnvironment = 'development' | 'production' | 'staging';

/**
 * @deprecated Please use useNewfrontLogoFavicon instead
 * Render the Newfront favicon based on the environment
 * @param environment
 */
export function useNewfrontFavicon(environment?: FaviconEnvironment): void {
  useFavicon(
    environment
      ? `https://legacy.newfront.com/static/favicon-${environment}.png`
      : `https://legacy.newfront.com/static/favicon-production.png`,
  );
}

/**
 * @deprecated Please consult with the frontend team about the modern approach to favicons
 */
// TODO(ACC-1335):Remove favicon from accounts app
export function useNewfrontLogoFavicon(): void {
  useFavicon(`https://dash.newfront-staging.com/favicon.png`);
}

/**
 * @deprecated Please use next/head <Head> component instead
 */
export function usePageTitle(title?: string): void {
  return useTitle(title ? `${title} | Newfront Insurance` : 'Newfront Insurance');
}

/**
 * Load the current CSRF token for this session.
 */
export function useXsrfToken(): string | null {
  const [token] = useCookie('XSRF-TOKEN');
  return token;
}

/**
 * Extends the useLocalStorage hook to prefix the key with a namespace
 */
const PREFIX_LOCAL_STORAGE = '@newfront/';
export function useNewfrontLocalStorage<T>(...params: Parameters<typeof useLocalStorage<T>>) {
  const [key, ...rest] = params;
  return useLocalStorage<T>(`${PREFIX_LOCAL_STORAGE}${key}`, ...rest);
}
